import React from 'react';
import './App.css';
import {Graph} from './Components/Graph'

function App() {
  return (
    <div className="App">
      <p style={{width:"60%"}}>
        Note: This is a prototype. Further explanatory text might be added. In the meantime, you can play around and answer irrational claims like
        </p>
      <p style={{color:"#BE0000"}}>
      "Even when keeping 2m distance you can get infected!"
        </p>
      
      <p style={{width:"60%"}}>
        Risk per contact matters too. Try to minimize it by keeping distance, washing hands often, etc.!
        Look here for more information: <br/><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public">WHO coronavirus advice</a>
      </p>
      <Graph w="400" h="200"></Graph>
      <p style={{width:"60%", fontSize:"15px"}}>
        Disclaimer: This illustration uses an extremely simplified model with a constant infection risk. The risk of not getting infected is multiplied by (1 - "risk of getting infected") at each new contact.
        </p>
    </div>
  );
}

export default App;
