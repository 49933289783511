import React, {useEffect, useMemo, useState} from 'react'
import {Slider, Typography} from '@material-ui/core';
import {LensOutlined} from '@material-ui/icons'
//import {LogValueLabel} from './LogValueLabel'

export function Graph(props) {
    const {w,h} = props;
    const [contacts, setContacts] = useState(100);
    const [riskIndex, setRiskIndex] = useState(1);

    const minRiskExp = 7, maxRiskExp = 1;
    const riskFromSliderIndex = (index) => {
        const riskExp = minRiskExp + 1 - index;
        return 1 / Math.pow(2, riskExp);
    };
    const risk = riskFromSliderIndex(riskIndex);
    const invRisk = 1/risk;

    const yVals = useMemo(() => {
        const vals = [];
        let val = 1.0;
        for(let i=0; i<contacts; i++) {
            vals.push(val);
            val *= (1.0-risk);
        }
        return vals;
    }, [contacts, risk]);

    const getCurvePathString = (w, h, yVals) => {
        const xMax = yVals.length - 1;
        let str = "M 0 0 ";
        for(let i=1; i<=xMax; i++) {
            const x = i/xMax * w;
            const y = h - yVals[i] * h;
            str += "L "+x+" "+y+" ";
        }
        str += "L "+w+" 0 ";
        return str;
    };

    const dStr = getCurvePathString(w, h, yVals);

    const probStrFromYVals = (yVals) => {
        const lastProb = yVals[yVals.length-1];
        const percent = (lastProb*100).toFixed(2);
        return percent;
    }
    const probStr = probStrFromYVals(yVals);

    return (
        <div style={{width: "600px"}}>
            <svg width={w} height={h} style={{backgroundColor: "#99dd99"}}>
                <g>
                    <path d={dStr}
                    fill="#6E0000"
                    strokeWidth="3"/>
                </g>
            </svg>
            <Typography id="discrete-slider" gutterBottom>
                Probability of not being infected after {contacts} contacts: {probStr}%
            </Typography>
            <Typography id="discrete-slider" gutterBottom>
                Risk of getting infected from each individual contact: 1/{invRisk}
            </Typography>
            <Slider
                value={riskIndex}
                valueLabelDisplay="off"
                step={1}
                marks
                min={maxRiskExp}
                max={minRiskExp}
                onChange={(ev, newVal) => {
                    setRiskIndex(newVal);
                }}
            />
            <Typography id="discrete-slider" gutterBottom>
                Contacts: {contacts}
            </Typography>
            <Slider
                defaultValue={30}
                valueLabelDisplay="off"
                step={10}
                marks
                min={10}
                max={1000}
                value={contacts}
                onChange={(ev, newValue) => {
                    setContacts(newValue);
                }}
                //ValueLabelComponent={LogValueLabel}
            />
        </div>
    );
}